<template>
  <div class="tw-flex tw-h-screen" id="company-container">
    <Sidebar v-if="!$route.meta.withoutSidebar" />
    <div
      class="tw-w-12/12 lg:tw-w-9/12 tw-flex-1 tw-flex tw-flex-col tw-overflow-hidden"
    >
      <Header />
      <router-view class="company-content"></router-view>
    </div>

    <DashboardRedirectionPopup
      v-if="canShowDashboardRedirectionPopup" 
      :canOpen="canShowDashboardRedirectionPopup" 
      @close="closeDashboardRedirectionPopup"
      @redirect="goToQuizResults"
    />
  </div>
</template>

<script>
import Sidebar from "./components/layout/company/Sidebar.vue";
import Header from "./components/layout/company/Header.vue";
import DashboardRedirectionPopup from "./features/gpmse/components/DashboardRedirectionPopup.vue";
export default {
  name: "Company",
  components: {
    Sidebar,
    Header,
    DashboardRedirectionPopup,
  },
  data() {
    return {
      canShowDashboardRedirectionPopup: false,
    };
  },
  mounted() {
    this.canShowDashboardRedirectionPopup = 
      this.$store.state.gpmseShowDashboardRedirectionPopup;
  },
  methods: {
    showMenu() {
      this.$el.querySelector("#sidebar").classList.toggle("tw-hidden");
    },
    closeDashboardRedirectionPopup() {
      this.canShowDashboardRedirectionPopup = false;
      this.$store.commit("SET_GPMSE_SHOW_DASHBOARD_REDIRECTION_POPUP", false);
    },
    goToQuizResults() {
      this.closeDashboardRedirectionPopup();
      this.$router.push({ name: 'Company.MonEntreprisePage.Questionnaire' })
    },
  }
};
</script>
