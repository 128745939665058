import FirstStepRegistrationPage from "../features/auth/pages/FirstStepRegistrationPage";
import SecondStepRegistrationPage from "../features/auth/pages/SecondStepRegistrationPage";
import LastStepRegistrationAddCollaborateurPage from "../features/auth/pages/LastStepRegistrationAddCollaborateurPage";
import LastStepRegistrationPage from "../features/auth/pages/LastStepRegistrationPage";
import Public from "@/Public";
import Assist from "@/Assist";
// import Login from "@/features/auth/pages/Login";
import PreInscriptionLogin from "@/features/auth/pages/preInscription/Login";
import PreInscriptionFirstStepRegistrationPage from "@/features/auth/pages/preInscription/FirstStepRegistrationPage";
import ResetPassword from "@/features/auth/pages/ResetPassword";
import NewPassword from "@/features/auth/pages/NewPassword";
import LoginSecondStep from "@/features/auth/pages/LoginSecondStep";
import Error404 from "@/features/404/pages/Error404";
import MaintenancePage from "@/features/404/pages/MaintenancePage";
import GdprRightsExercisePage from "@/features/auth/pages/GdprRightsExercisePage";
import TokenExpired from "@/features/404/pages/TokenExpired";
import HomePage from "@/features/auth/pages/HomePage";
import DoubleEmailOrSiren from "@/features/auth/pages/DoubleEmailOrSiren";

import GDPRPublicQuizStart from "@/features/auth/pages/PublicQuiz/GDPRPublicQuizStart";
import GDPRPublicQuiz from "@/features/auth/pages/PublicQuiz/GDPRPublicQuiz";
import GDPRPublicQuizSignup from "@/features/auth/pages/PublicQuiz/GDPRPublicQuizSignup";
import GDPRPublicQuizSuccess from "@/features/auth/pages/PublicQuiz/GDPRPublicQuizSuccess";

// landing-page

import AddSousTraitant from "@/features/auth/pages/Collaborateurs/AddSousTraitant";
import invitationSousTraitantPage from "@/features/auth/pages/Collaborateurs/invitationSousTraitantPage";
import RegistrationSubContractorPage from "@/features/auth/pages/Collaborateurs/RegistrationSubContractorPage";
import RegistrationSubContractorLastPage from "@/features/auth/pages/Collaborateurs/RegistrationSubContractorLastPage";
import SetupAcountCompanyPage from "@/features/auth/pages/SetupAcountCompanyPage";
import SetupAcountPricingCompanyPage from "@/features/auth/pages/SetupAcountPricingCompanyPage";
import NewGouvernanceAcceptReceipt from "@/features/company/pages/MonEntreprise/Collaborateurs/Gouvernance/NewGouvernanceAcceptReceipt";
import RegisterActivityWithToken from "@/features/ProcessingRegister/pages/RegisterActivityWithToken";
import RegisterFinishQuiz from "@/features/ProcessingRegister/pages/RegisterFinishQuiz";
import PreRegisterExpertPage from "@/features/auth/pages/expert/PreRegisterExpertPage";
import PreRegisterConfirmExpertPage from "@/features/auth/pages/expert/PreRegisterConfirmExpertPage";
import ExpertFirstStepRegistrationPage from "@/features/auth/pages/expert/ExpertFirstStepRegistrationPage";
import ExpertSecondStepRegistrationPage from "@/features/auth/pages/expert/ExpertSecondStepRegistrationPage";
import ExpertLastStepRegistrationPage from "@/features/auth/pages/expert/ExpertLastStepRegistrationPage";
import ExpertCollaboratorLastStepRegistrationPage from "@/features/auth/pages/expert/ExpertCollaboratorLastStepRegistrationPage";
// import ExpertFirstStepLogin from "@/features/auth/pages/expert/ExpertFirstStepLogin";
// import ExpertSecondStepLogin from "@/features/auth/pages/expert/ExpertSecondStepLogin";

import VerifiedSubContractorPage from "@/features/auth/pages/Collaborateurs/VerifiedSubContractorPage";
import MessageEmailSending from "@/features/auth/pages/Collaborateurs/MessageEmailSending";
import VerifiedSubContractorStepTwoPage from "@/features/auth/pages/Collaborateurs/VerifiedSubContractorStepTwoPage";
import VerifiedSubContractorRegisterPage from "@/features/auth/pages/Collaborateurs/VerifiedSubContractorRegisterPage";
import VerifiedSubContractorQuizPage from "@/features/auth/pages/Collaborateurs/VerifiedSubContractorQuizPage";
import VerifiedSubContractorScorePage from "@/features/auth/pages/Collaborateurs/VerifiedSubContractorScorePage";
import VerifiedSubContractorSuccessPage from "@/features/auth/pages/Collaborateurs/VerifiedSubContractorSuccessPage";

import ExpertCollaboratorSecondStepPage from "@/features/auth/pages/expert/ExpertCollaboratorSecondStepPage";
import SetupAcountExpertPage from "@/features/auth/pages/expert/SetupAcountExpertPage";

import GdprQuizGpmseLanding from "@/features/auth/pages/PreinscriptationGpmse/Landing";
import GdprQuizGpmseRegister from "@/features/auth/pages/PreinscriptationGpmse/PreRegister";
import GdprQuizGpmseQuiz from "@/features/auth/pages/PreinscriptationGpmse/Quiz";
import GdprQuizGpmseScore from "@/features/auth/pages/PreinscriptationGpmse/Score";
import GdprQuizGpmseSuccess from "@/features/auth/pages/PreinscriptationGpmse/Success";
import GdprQuizGpmseFollowup from "@/features/gpmse/pages/Followup";
import GdprQuizGpmseFollowupScore from "@/features/gpmse/pages/FollowupScore";
import GdprQuizGpmseFollowupScoreDetail from "@/features/gpmse/pages/FollowupScoreDetail";

import EmailLogin from "../features/auth/pages/MultiAccount/EmailLogin.vue";
import PasswordLogin from "../features/auth/pages/MultiAccount/PasswordLogin.vue";
import CompanySelection from "../features/auth/pages/MultiAccount/CompanySelection.vue";
import OtpLogin from "../features/auth/pages/MultiAccount/OtpLogin.vue";

// Collaborateurs signup page
const routes = [
  {
    name: "GdprQuizGpmse.landing",
    path: "/gpmse/landing",
    component: GdprQuizGpmseLanding,
    meta: {
      requiresAuth: false,
      requiresPlan: false,
    },
  },
  {
    name: "GdprQuizGpmse.register",
    path: "/gpmse/preinscription",
    component: GdprQuizGpmseRegister,
    meta: {
      requiresAuth: false,
      requiresPlan: false,
    },
  },
  {
    name: "GdprQuizGpmse.quiz",
    path: "/gpmse/questionnaire",
    component: GdprQuizGpmseQuiz,
    meta: {
      requiresAuth: false,
      requiresPlan: false,
    },
  },
  {
    name: "GdprQuizGpmse.score",
    path: "/gpmse/score",
    component: GdprQuizGpmseScore,
    meta: {
      requiresAuth: false,
      requiresPlan: false,
    },
  },
  {
    name: "GdprQuizGpmse.success",
    path: "/gpmse/success",
    component: GdprQuizGpmseSuccess,
    meta: {
      requiresAuth: false,
      requiresPlan: false,
    },
  },
  {
    name: "GdprQuizGpmse.quizFollowup",
    path: "/gpmse/suivi-questionnaire",
    component: GdprQuizGpmseFollowup,
    meta: {
      requiresAuth: true,
      requiresPlan: false,
    },
  },
  {
    name: "GdprQuizGpmse.quizFollowup.Score",
    path: "/gpmse/suivi-questionnaire/Score/:userId",
    component: GdprQuizGpmseFollowupScore,
    meta: {
      requiresAuth: true,
      requiresPlan: false,
    },
  },
  {
    name: "GdprQuizGpmse.quizFollowup.Score.Detail",
    path: "/gpmse/suivi-questionnaire/Score/Detail/:userId",
    component: GdprQuizGpmseFollowupScoreDetail,
    meta: {
      requiresAuth: true,
      requiresPlan: false,
    },
  },

  {
    name: "GdprPublicQuiz.start",
    path: "/gdpr-public-quiz/start",
    component: GDPRPublicQuizStart,
    meta: {
      requiresAuth: false,
      requiresPlan: false,
    },
  },
  {
    name: "GdprPublicQuiz.quiz",
    path: "/gdpr-public-quiz/quiz",
    component: GDPRPublicQuiz,
    meta: {
      requiresAuth: false,
      requiresPlan: false,
    },
  },
  {
    name: "GdprPublicQuiz.signup",
    path: "/gdpr-public-quiz/signup",
    component: GDPRPublicQuizSignup,
    meta: {
      requiresAuth: false,
      requiresPlan: false,
    },
  },
  {
    name: "GdprPublicQuiz.success",
    path: "/gdpr-public-quiz/success",
    component: GDPRPublicQuizSuccess,
    meta: {
      requiresAuth: false,
      requiresPlan: false,
    },
  },
  {
    name: "Gouvernance.AcceptReceipt",
    path: "/governance/accept-receipt/:token",
    props: true,
    component: NewGouvernanceAcceptReceipt,
  },
  {
    name: "Register.Activity.Token.Page",
    path: "/register/activity/:token",
    props: true,
    component: RegisterActivityWithToken,
  },
  {
    name: "Register.Activity.Token.Finish",
    path: "/register/activity/finish-quiz/:token",
    props: true,
    component: RegisterFinishQuiz,
  },
  {
    name: "MaintenancePage",
    path: "/maintenance",
    component: MaintenancePage,
  },
  {
    name: "Home",
    path: "/",
    component: Public,
    children: [
      {
        name: "ErrorPage",
        path: "404",
        meta: {
          requiresAuth: false,
          requiresPlan: false,
        },
        component: Error404,
      },
      {
        name: "GdprRightsExercisePage",
        path: "/gdpr-rights-exercise",
        component: GdprRightsExercisePage,
      },
      {
        name: "TokenExpired",
        path: "token-invalid",
        meta: {
          requiresAuth: false,
          requiresPlan: false,
        },
        component: TokenExpired,
      },
      {
        path: "",
        name: "landing-public",
        meta: {
          requiresAuth: false,
          requiresPlan: false,
        },
        component: HomePage,
      },
      {
        name: "PreInscriptionLogin",
        path: "pre-inscription/login",
        meta: {
          requiresAuth: false,
          requiresPlan: false,
        },
        component: PreInscriptionLogin,
      },
      {
        name: "PreInscriptionFirstStep",
        path: "pre-inscription/registration/first-step/:token",
        meta: {
          requiresAuth: false,
          requiresPlan: false,
        },
        component: PreInscriptionFirstStepRegistrationPage,
      },
    ],
  },

  {
    name: "public",
    path: "/auth",
    component: Public,
    children: [
      {
        path: "",
        name: "landing-public",
        meta: {
          requiresAuth: false,
        },
        component: HomePage,
      },
      {
        name: "signup",
        path: "registration",
        meta: {
          requiresAuth: false,
        },
        component: FirstStepRegistrationPage,
      },
      {
        path: "registration/second-step/:token",
        name: "signupSecond",
        meta: {
          requiresAuth: false,
          requiresPlan: false,
        },
        component: SecondStepRegistrationPage,
      },
      {
        path: "registration/add-collaborateurs/last-step/:token",
        name: "signupLast.addCollaborateurs",
        meta: {
          requiresAuth: false,
          requiresPlan: false,
        },
        component: LastStepRegistrationAddCollaborateurPage,
      },
      {
        path: "registration/last-step/:token",
        name: "signupLast",
        meta: {
          requiresAuth: false,
          requiresPlan: false,
        },
        component: LastStepRegistrationPage,
      },
      {
        name: "DoubleEmailOrSiren",
        path: "double-siren-or-email",
        props: true,
        component: DoubleEmailOrSiren,
      },
      // {
      //   name: "LoginPage",
      //   path: "login",
      //   meta: {
      //     requiresAuth: false,
      //     requiresPlan: false,
      //   },
      //   component: Login,
      // },

      // MultiAccount login
      {
        path: "login",
        name: "LoginPage",
        component: EmailLogin,
        meta: {
          requiresAuth: false,
          requiresPlan: false,
        }
      },
      {
        path: "login/password",
        name: "MultiAccount.PasswordLogin",
        component: PasswordLogin,
        meta: {
          requiresAuth: false,
          requiresPlan: false,
        }
      },
      {
        path: "login/selection",
        name: "MultiAccount.CompanySelection",
        component: CompanySelection,
        meta: {
          requiresAuth: false,
          requiresPlan: false,
        }
      },
      {
        path: "login/otp",
        name: "MultiAccount.OTPLogin",
        component: OtpLogin,
        meta: {
          requiresAuth: false,
          requiresPlan: false,
        }
      },

      {
        name: "TwoFactorValidation",
        path: "login/two-factor-validation/:token",
        props: true,
        meta: {
          requiresAuth: false,
          requiresPlan: false,
        },
        component: LoginSecondStep,
      },
      {
        name: "SetupAccountExpert",
        path: "setup-account-expert",
        props: true,
        meta: {
          requiresAuth: true,
          requiresPlan: false,
        },
        component: SetupAcountExpertPage,
      },
      {
        name: "SetupAccountCompany",
        path: "setup-account",
        props: true,
        meta: {
          requiresAuth: true,
          requiresPlan: false,
        },
        component: SetupAcountCompanyPage,
      },
      {
        name: "SetupAccountPricingCompany",
        path: "setup-account-pricing/:planId",
        props: true,
        meta: {
          requiresAuth: true,
          requiresPlan: true,
        },
        component: SetupAcountPricingCompanyPage,
      },
      {
        path: "reset",
        name: "reset",
        meta: {
          requiresAuth: false,
          requiresPlan: false,
        },
        component: ResetPassword,
      },
      {
        path: "reset-password/:token",
        name: "new-password",
        meta: {
          requiresAuth: false,
          requiresPlan: false,
        },
        component: NewPassword,
      },
      //collaborators entreprise signup Page
      {
        name: "collaborator.signup",
        path: "collaborator/registration/first-step/:token",
        meta: {
          requiresAuth: false,
        },
        component: () => import("../features/auth/pages/Collaborateurs/signup/CollaborateurFirstStepRegistrationPage"),
      },
      {
        name: "collaborator.signup.secondStep",
        path: "collaborator/registration/second-step/:token",
        meta: {
          requiresAuth: false,
        },
        component: () => import("../features/auth/pages/Collaborateurs/signup/CollaborateurSecondStepRegistrationPage"),
      },
      {
        name: "collaborator.signup.thirdStep",
        path: "collaborator/registration/third-step/:token",
        meta: {
          requiresAuth: false,
        },
        component: () => import("../features/auth/pages/Collaborateurs/signup/CollaborateurThirdStepRegistrationPage"),
      },
      //collaborators new Page
      {
        path: "collaborator/:token",
        name: "AddSousTraitant",
        meta: {
          requiresAuth: false,
          requiresPlan: false,
        },
        component: AddSousTraitant,
      },
      {
        path: "collaborator-invitation/:token",
        name: "MsgSousTraitant",
        meta: {
          requiresAuth: false,
          requiresPlan: false,
        },
        component: invitationSousTraitantPage,
      },
      {
        path: "invitation/:token",
        name: "SubContractor.AddSubContractor.SecondStep",
        component: RegistrationSubContractorPage,
        meta: {
          requiresAuth: false,
          requiresPlan: false,
        },
      },
      {
        path: "invitation/expert/collaborator/:token",
        name: "Expert.Collaborator.SecondStep",
        component: ExpertCollaboratorSecondStepPage,
        meta: {
          requiresAuth: false,
          requiresPlan: false,
        },
      },
      {
        path: "invitation/last-step/:token",
        name: "SubContractor.AddSubContractor.LastStep",
        component: RegistrationSubContractorLastPage,
        meta: {
          requiresAuth: false,
          requiresPlan: false,
        },
      },
      // Viqtor Expert
      {
        path: "registration-expert",
        name: "Auth.Registration.Expert.PreRegister",
        component: PreRegisterExpertPage,
        meta: {
          requiresAuth: false,
          requiresPlan: false,
        },
      },
      {
        path: "registration-expert-confirmation/:token",
        name: "Auth.Registration.Expert.PreRegisterConfirm",
        component: PreRegisterConfirmExpertPage,
        meta: {
          requiresAuth: false,
          requiresPlan: false,
        },
        props: true,
      },
      {
        path: "registration-expert-first-step/:token",
        name: "Auth.Registration.Expert.FirstStep",
        component: ExpertFirstStepRegistrationPage,
        meta: {
          requiresAuth: false,
          requiresPlan: false,
        },
      },
      {
        path: "registration-expert-second-step/:token",
        name: "Auth.Registration.Expert.SecondStep",
        component: ExpertSecondStepRegistrationPage,
        meta: {
          requiresAuth: false,
          requiresPlan: false,
        },
      },
      {
        path: "registration-expert-last-step/:token",
        name: "Auth.Registration.Expert.LastStep",
        component: ExpertLastStepRegistrationPage,
        meta: {
          requiresAuth: false,
          requiresPlan: false,
        },
      },
      {
        path: "registration-expert-collaborator-last-step/:token",
        name: "Auth.Registration.Expert.Collaborator.LastStep",
        component: ExpertCollaboratorLastStepRegistrationPage,
        meta: {
          requiresAuth: false,
          requiresPlan: false,
        },
      },
      // {
      //   path: "expert-login",
      //   name: "ExpertLoginPage",
      //   component: ExpertFirstStepLogin,
      //   meta: {
      //     requiresAuth: false,
      //     requiresPlan: false,
      //   },
      // },
      // {
      //   path: "expert-login/two-factor-validation/:token",
      //   name: "ExpertLoginPageTwo",
      //   component: ExpertSecondStepLogin,
      //   meta: {
      //     requiresAuth: false,
      //     requiresPlan: false,
      //   },
      // },
    ],
  },
  {
    name: "assist",
    path: "/assist",
    component: Assist,
    children: [
      // verified invitation
      {
        path: "verified-invitation/:token/:id",
        name: "SubContractor.AddSubContractor.VerifiedStep",
        component: VerifiedSubContractorPage,
        meta: {
          requiresAuth: false,
          requiresPlan: false,
        },
      },
      {
        path: "email-sending",
        name: "SubContractor.EmailSending",
        component: MessageEmailSending,
        meta: {
          requiresAuth: false,
          requiresPlan: false,
        },
      },
      {
        path: "verified-invitation-two/:token/:id",
        name: "SubContractor.AddSubContractor.VerifiedStepTwo",
        component: VerifiedSubContractorStepTwoPage,
        meta: {
          requiresAuth: false,
          requiresPlan: false,
        },
      },
      {
        path: "register/:token",
        name: "SubContractor.AddSubContractor.Register",
        component: VerifiedSubContractorRegisterPage,
        meta: {
          requiresAuth: false,
          requiresPlan: false,
        },
      },
      {
        path: "quiz/:token",
        name: "SubContractor.AddSubContractor.Quiz",
        component: VerifiedSubContractorQuizPage,
        meta: {
          requiresAuth: false,
          requiresPlan: false,
        },
      },
      {
        path: "score/:token",
        name: "SubContractor.AddSubContractor.Score",
        component: VerifiedSubContractorScorePage,
        meta: {
          requiresAuth: false,
          requiresPlan: false,
        },
      },
      {
        path: "success/:token",
        name: "SubContractor.AddSubContractor.Success",
        component: VerifiedSubContractorSuccessPage,
        meta: {
          requiresAuth: false,
          requiresPlan: false,
        },
      },
    ],
  },
];
export default routes;
