<template>
  <div id="Questionnaire-container" class="tw-flex tw-flex-col tw-h-full">
    <div class="tw-py-6 tw-px-12 tw-mx-auto" style="width: 75%;">
      <EntrepriseHeaderBlockTextComponent
        class="tw-mt-10"
        :title="`Questionnaire RGPD ${socialReason}`"
      />

      <SignupSecondaryTextComponent class="mx-16 public-text-register tw-mt-12 tw-text-center">
        Parfait !<br /><br />
        Vous avez intégralement complété votre questionnaire d'évaluation.
      </SignupSecondaryTextComponent>

      <div class="tw-grid tw-gap-x-32 tw-w-full tw-justify-items-center">
        <div class="box-questionnaire">
          <h2>Mon évaluation</h2>
          <span class="score" :class="getColorBadge()">
            {{ total !== null ? total.toFixed() : 0 }} / 100
          </span>
          <hr />
          <p>
            Du changement concernant votre conformité ?<br />
            Une fois par an, vous avez la possibilité de modifier vos réponses au questionnaire<br />
            et faire évoluer votre score.
          </p>
        </div>
      </div>

      <div class="tw-flex tw-justify-center tw-pb-12 md:tw-pb-20 tw-mt-16">
        <PrimaryButtonComponent
          :style="{
            backgroundColor: `${isSendingForm ? '#F4F4F4' : theme.ctaBgColor} !important`,
            color: `${isSendingForm ? 'black' : theme.ctaTextColor} !important`,
            borderColor: `${isSendingForm ? '#F4F4F4' : theme.ctaBgColor} !important`,
          }"
          :loading="isSendingForm"
          :disabled="isSendingForm"
          @click="saveProgress"
        >Sauvegarder mon évaluation</PrimaryButtonComponent>
      </div>
    </div>
  </div>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import SignupSecondaryTextComponent from "@/features/auth/components/SignupSecondaryTextComponent";
import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";
import { assistQuizSave } from "@/features/auth/services/appApi";

export default {
  name: "VerifiedSubContractorScorePage",
  components: {
    EntrepriseHeaderBlockTextComponent,
    SignupSecondaryTextComponent,
    PrimaryButtonComponent,
  },
  data() {
    return {
      scoreTotal: null,
      total: 0,
      isSendingForm: false,
    };
  },
  computed: {
    theme() {
      return this.$store.state.referrer.theme;
    },
    socialReason() {
      return this.$store.state.assistCompanySocialReason;
    }
  },
  mounted() {
    if (!this.$store.state.assistCompanySocialReason)
      this.$router.push({ name: "SubContractor.AddSubContractor.Register" });

    this.populateFromStore();
  },
  methods: {
    populateFromStore() {
      const quiz = this.$store.getters.getAssistQuizChaptersWithScore;
      if (!quiz.quizChapters) this.$router.push({ name: "SubContractor.AddSubContractor.Register" });

      this.scoreTotal = quiz.status;
      this.total = quiz.scorePercent;
    },
    getColorBadge() {
      if (this.scoreTotal =="OK_GO") return "victor-green";
      else if (this.scoreTotal =="GO_SR_A") return "victor-yellow";
      else if (this.scoreTotal =="GO_SR_B") return "victor-yellow";
      else return "victor-red";
    },
    saveProgress() {
      this.isSendingForm = true;

      assistQuizSave(this.$route.params.token, { 
        quizForm: { chapters: this.$store.getters.getAssistQuizChapters },
      })
        .then(() => {
          this.isSendingForm = false;
          this.$store.commit("SET_ASSIST_QUIZ_CHAPTERS", []);
          this.$store.commit("SET_ASSIST_QUIZ_CHAPTERS_WITH_SCORE", []);
          this.$store.commit("SET_ASSIST_COMPANY_SOCIAL_REASON", "");
          this.$router.push({ 
            name: "SubContractor.AddSubContractor.Success", 
            params: { token: this.$route.params.token } 
          });
        })
        .catch(err => {
          this.isSendingForm = false;
          console.log(err);
          this.$snackbar.showAlertMessage({
            message: err?.response?.data?.message || "Une erreur est survenue lors de la sauvegarde de votre évaluation",
            type: "error",
          });
        });
    },
  }
}
</script>