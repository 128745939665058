<template>
  <div id="Questionnaire-container"  class="tw-flex tw-flex-col tw-h-full">
    <div class="tw-py-6 tw-px-12">
      <div class="tw-flex tw-items-center tw-justify-between">
        <img :src="logo" style="width: 170px;" alt="LOGO" />
        <span @click="logout" class="tw-cursor-pointer" :style="{ color: theme.ctaBgColor }">Se déconnecter</span>
      </div>

      <EntrepriseHeaderBlockTextComponent class="tw-mt-10" title="Back-office"
        :description="socialReason ? `Détail du questionnaire de ${socialReason}.` : ''" />

      <div class="tw-items-center tw-flex tw-flex-col">
        <template v-if="loading">
          <content-placeholders class="marginTop">
            <content-placeholders-text :lines="10" />
          </content-placeholders>
        </template>
        <template v-else>
          <div class="tw-grid tw-grid-cols-5 tw-gap-8 tw-justify-items-center">
            <div class="tw-col-span-5 lg:tw-col-span-2 box-questionnaire">
              <h2>Mon questionnaire</h2>
              <span class="score" :class="getColorBadge()">
                {{ total !== null ? total.toFixed() : 0 }} / 100
              </span>
              <hr />
              <p>
                Du changement concernant votre conformité ?
                Une fois par an, vous avez la possibilité de modifier vos réponses
                au questionnaire et faire évoluer votre score.
              </p>
            </div>
            <div class="tw-col-span-5 lg:tw-col-span-3 box-score">
              <h2>Mon score en détails</h2>
              <v-simple-table id="contact-table">
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th>Chapitre</th>
                    <th>Score</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, key) in table_data" :key="item.id">
                    <td>{{ key + 1 }}. {{ item.chapter.label }}</td>
                    <td>
                      <span :class="getBadge(item.status)"
                      >{{ item.scorePercent !== null ? item.scorePercent.toFixed() : 0 }} / 100</span
                      >
                    </td>
                  </tr>

                  <tr>
                    <td>Total:</td>
                    <td>
                      <span class="tw-whitespace-nowrap" :class="getLgBadge()"
                      >{{ total !== null ? total.toFixed() : 0 }}  / 100</span>
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <div class="tw-w-full tw-flex tw-items-center tw-justify-center">
                <v-btn
                  elevation="1"
                  text
                  rounded
                  large
                  @click="$router.push({ name: 'GdprQuizGpmse.quizFollowup.Score.Detail', params: { userId: $route.params.userId }})"
                  class="tw-text-white victor-submit-button-detail"
                >
                  Voir le détail
                </v-btn>
              </div>
            </div>
          </div>
        </template>

        <v-btn
          v-if="!loading"
          elevation="1" text rounded large
          :style="{
            backgroundColor: `${theme.ctaBgColor} !important`,
            color: `${theme.ctaTextColor} !important`,
            borderColor: `${theme.ctaBgColor} !important`,
          }"
          style="width: 120px;"
          class="tw-mt-4"
          @click="$router.back()"
        >Retour</v-btn>
      </div>
    </div>

    <Footer class="tw-w-full tw-mt-auto" />
  </div>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import Footer from "@/components/layout/Footer";
import { getQuiz } from "@/features/gpmse/services/api";

export default {
  name: "GdprQuizGpmseFollowupScore",
  components: {
    EntrepriseHeaderBlockTextComponent,
    Footer,
  },
  data() {
    return {
      socialReason: null,
      loading: false,
      scoreTotal: null,
      total: null,
      table_data: [],
    }
  },
  computed: {
    logo() {
      return this.$store.state.referrer.logo;
    },
    theme() {
      return this.$store.state.referrer.theme;
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;
      getQuiz(this.$route.params.userId)
        .then(({ data }) => {
          this.socialReason = data.data.socialReason;
          this.table_data   = data.data.quiz.quizChapters.sort((a, b) => a.chapterId - b.chapterId);
          this.scoreTotal   = data.data.quiz.status;
          this.showQuestion = !data.data.quiz.isDraft;
          this.total = data.data.quiz.scorePercent;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.$snackbar.showAlertMessage({
            message: error?.response?.data?.message 
              || 'Une erreur est survenue lors de la récupération des données',
            type: "error",
          });
          this.loading = false;
        })
    },
    getBadge(score) {
      if (score =="A") return "victor-badge-green";
      else if (score =="B") return "victor-badge-yellow";
      else return "victor-badge-red";
    },
    getColorBadge() {
      if (this.scoreTotal =="OK_GO") return "victor-green";
      else if (this.scoreTotal =="GO_SR_A") return "victor-yellow";
      else if (this.scoreTotal =="GO_SR_B") return "victor-yellow";
      else return "victor-red";
    },
    getLgBadge() {
      if (this.scoreTotal =="OK_GO") return "victor-lg-badge-green";
      else if (this.scoreTotal =="GO_SR_A") return "victor-lg-badge-yellow";
      else if (this.scoreTotal =="GO_SR_B") return "victor-lg-badge-yellow";
      else return "victor-lg-badge-red";
    },
    logout() {
      this.$store.dispatch('logout');
      this.$router.push({ name: 'LoginPage' });
    },
  }
}
</script>