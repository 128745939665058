<template>
  <div id="Questionnaire-container" class="tw-flex tw-flex-col tw-h-full">
    <div class="tw-py-6 tw-px-12">
      <img :src="logo" style="width: 170px;" alt="LOGO" />
      
      <EntrepriseHeaderBlockTextComponent
        class="tw-mt-10"
        :title="`Évaluation RGPD de ${socialReason}`"
      />

      <SignupSecondaryTextComponent class="mx-16 public-text-register tw-mt-4 tw-text-center">
        Merci !<br /><br />
        Votre questionnaire d’évaluation est intégralement complété. <br />
        Votre score a été transmis au GPMSE.
      </SignupSecondaryTextComponent>

      <div class="tw-grid tw-gap-x-32 tw-w-full tw-justify-items-center">
        <div class="box-questionnaire">
          <h2>Mon évaluation</h2>
          <span class="score" :class="getColorBadge()">
            {{ total !== null ? total.toFixed() : 0 }} / 100
          </span>
          <hr />
          <p>
            Pour accéder aux détails de votre évaluation, <br />
            qui restent des données confidentielles, laissez-vous guider.
          </p>
        </div>
      </div>

      <div class="tw-flex tw-justify-center tw-pb-12 md:tw-pb-20 tw-mt-16">
        <PrimaryButtonComponent
          :style="{
            backgroundColor: `${isSendingForm ? '#F4F4F4' : theme.ctaBgColor} !important`,
            color: `${isSendingForm ? 'black' : theme.ctaTextColor} !important`,
            borderColor: `${isSendingForm ? '#F4F4F4' : theme.ctaBgColor} !important`,
          }"
          :loading="isSendingForm"
          :disabled="isSendingForm"
          @click="saveProgress"
        >Sauvegarder mon évaluation</PrimaryButtonComponent>
      </div>
    </div>

    <Footer class="tw-w-full tw-mt-auto" />
  </div>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import SignupSecondaryTextComponent from "@/features/auth/components/SignupSecondaryTextComponent";
import Footer from "@/components/layout/Footer";
import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";
import { registerNotify } from "@/features/auth/services/gpmse.api"

export default {
  name: "GdprQuizGpmseScore",
  components: {
    EntrepriseHeaderBlockTextComponent,
    SignupSecondaryTextComponent,
    Footer,
    PrimaryButtonComponent,
  },
  data() {
    return {
      scoreTotal: null,
      total: 0,
      table_data: [],
      isSendingForm: false,
    };
  },
  computed: {
    logo() {
      return this.$store.state.referrer.logo;
    },
    theme() {
      return this.$store.state.referrer.theme;
    },
    socialReason() {
      return this.$store.state.gpmseCompanySocialReason;
    }
  },
  mounted() {
    if (
      !this.$store.state.gpmseUserToken ||
      !this.$store.state.gpmseCompanySocialReason
    )
      this.$router.push({ name: "GdprQuizGpmse.register" });

    this.populateFromStore();
  },
  methods: {
    populateFromStore() {
      const quiz = this.$store.getters.getGpmseQuizChaptersWithScore;
      if (!quiz.quizChapters) this.$router.push({ name: "GdprQuizGpmse.register" });

      this.table_data = quiz.quizChapters.sort((a, b) => a.chapterId - b.chapterId);
      this.scoreTotal = quiz.status;
      this.showQuestion = quiz.isDraft;
      this.total = quiz.scorePercent;
    },
    getColorBadge() {
      if (this.scoreTotal =="OK_GO") return "victor-green";
      else if (this.scoreTotal =="GO_SR_A") return "victor-yellow";
      else if (this.scoreTotal =="GO_SR_B") return "victor-yellow";
      else return "victor-red";
    },
    saveProgress() {
      this.isSendingForm = true;

      registerNotify({ 
        token: this.$store.state.gpmseUserToken || "",
        quizForm: { chapters: this.$store.getters.getGpmseQuizChapters },
      })
      .then(() => {
        this.isSendingForm = false;
        this.$store.commit("SET_GPMSE_USER_TOKEN", null);
        this.$store.commit("SET_GPMSE_QUIZ_CHAPTERS", []);
        this.$store.commit("SET_GPMSE_QUIZ_CHAPTERS_WITH_SCORE", []);
        this.$router.push({ name: "GdprQuizGpmse.success" });
      })
      .catch(err => {
        this.isSendingForm = false;
        console.log(err);
        this.$snackbar.showAlertMessage({
          message: err.response.data.message,
          type: "error",
        });
      });
    },
  }
}
</script>