<template>
  <div>
    <template v-if="isDataLoading">
      <content-placeholders>
        <content-placeholders-text :lines="10" />
      </content-placeholders>
    </template>
    <template v-else>
      <v-row>
        <v-col cols="12" md="6" lg="4" class="custom-cols">
          <text-field-component class="register-activity-input" label="" placeholder="Nom" v-model="form.lastName"
            :error-messages="formValidation.lastName" />
        </v-col>
        <v-col cols="12" md="6" lg="4" class="custom-cols">
          <text-field-component class="register-activity-input" placeholder="Prénom" v-model="form.firstName"
            :error-messages="formValidation.firstName" />
        </v-col>
        <v-col cols="12" md="6" lg="4" class="custom-cols">
          <text-field-component class="register-activity-input" placeholder="Service" v-model="form.fonction"
            :error-messages="formValidation.fonction" />
        </v-col>
        <v-col cols="12" md="6" lg="4" class="custom-cols">
          <text-field-component class="register-activity-input" placeholder="Fonction occupée" v-model="form.position"
            :error-messages="formValidation.position" />
        </v-col>
        <v-col cols="12" md="6" lg="4" class="custom-cols">
          <text-field-component class="register-activity-input" placeholder="Téléphone fixe entreprise"
            v-model="form.phone" :error-messages="formValidation.phone" />
        </v-col>
        <v-col cols="12" md="6" lg="4" class="custom-cols">
          <text-field-component class="register-activity-input" placeholder="Adresse e-mail" v-model="form.email"
            :error-messages="formValidation.email" />
        </v-col>
        <v-col cols="12" md="6" lg="4" class="custom-cols">
          <text-field-component class="register-activity-input" placeholder="Adresse professionnelle, N° et rue"
            v-model="form.address" :error-messages="formValidation.address" />
        </v-col>
        <v-col cols="12" md="6" lg="4" class="custom-cols">
          <text-field-component class="register-activity-input" placeholder="Code postal" v-model="form.postalCode"
            :error-messages="formValidation.postalCode" />
        </v-col>
        <v-col cols="12" md="6" lg="4" class="custom-cols">
          <text-field-component class="register-activity-input" placeholder="Ville" v-model="form.city"
            :error-messages="formValidation.city" />
        </v-col>
        <v-col cols="12" class="custom-cols tw-text-right">
          <PrimaryButtonComponent @click="submit" :loading="isFormLoading" class="register-activity-validate-form"
            :style="{
              backgroundColor: `${referer.theme.ctaBgColor} !important`,
              color: `${referer.theme.ctaTextColor} !important`
            }">
            Valider
          </PrimaryButtonComponent>
        </v-col>
      </v-row>
    </template>

  </div>
</template>

<script>
import TextFieldComponent from "@/components/common/TextFieldComponent";
import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";
import { updateRegisterUserData } from "@/features/ProcessingRegister/services/appApi";
export default {
  name: "RegisterActivityForm",
  components: { PrimaryButtonComponent, TextFieldComponent },
  props: {
    register: {
      type: Object,
      default: () => { },
    }
  },
  data() {
    return {
      isDataLoading: false,
      isFormLoading: false,
      form: {
        lastName: '',
        firstName: '',
        email: '',
        fonction: '',
        position: '',
        phone: '',
        address: '',
        postalCode: '',
        city: ''
      },
      formValidation: {
        lastName: null,
        firstName: null,
        fonction: null,
        position: null,
        phone: null,
        email: null,
        address: null,
        postalCode: null,
        city: null
      },
      isDataValidated: false
    }
  },
  computed: {
    referer() {
      return this.$store.state.referrer;
    }
  },
  mounted() {
    this.isDataLoading = true;
    setTimeout(() => {
      this.form = {
        lastName: this.register.collaborateur.lastName,
        firstName: this.register.collaborateur.firstName,
        email: this.register.collaborateur.email,
        fonction: this.register.collaborateur.service,
        position: this.register.collaborateur.functionOccupied,
        phone: this.register.collaborateur.phone,
        address: this.register.collaborateur.address,
        postalCode: this.register.collaborateur.zipCode,
        city: this.register.collaborateur.city
      }
      this.isDataLoading = false;
    }, 1000);
  },
  methods: {
    submit() {
      this.isFormLoading = true;
      this.resetValidation();
      const data = { ...this.form };
      if (this.form.email === this.register.collaborateur.email) {
        delete data.email
      }
      updateRegisterUserData(data, this.$route.params.token)
        .then(response => {
          this.isDataValidated = true;
          this.$snackbar.showAlertMessage({
            type: 'success',
            message: response.data.message,
          })
        })
        .catch(({ response }) => {
          this.isDataValidated = false;
          if (response.status === 400) {
            Object.entries(response.data.errors).forEach(([key, value]) => {
              this.formValidation[key] = this.getValidationMessage(key, value);
            });
          }
        })
        .finally(() => this.isFormLoading = false);
    },
    resetValidation() {
      this.formValidation = {
        lastName: null,
        firstName: null,
        fonction: null,
        position: null,
        phone: null,
        email: null,
        address: null,
        postalCode: null,
        city: null
      }
    },
    getValidationMessage(key, value) {
      switch (key) {
        case 'firstName':
          return [value[0].replace('firstName', 'prénom')];
        case 'lastName':
          return [value[0].replace('lastName', 'nom')];
        case 'email':
          return [value[0].replace('email', 'email')];
        case 'phone':
          return [value[0].replace(/\bphone\b/, 'numéro de téléphone')];
        case 'fonction':
          return [value[0].replace('fonction', 'service')];
        case 'position':
          return [value[0].replace('position', 'position')];
        case 'address':
          return [value[0].replace('address', 'adresse')];
        case 'postalCode':
          return [value[0].replace('postalCode', 'code postale')];
        case 'city':
          return [value[0].replace('city', 'ville')];
        default:
          return value;
      }
    }
  }
}
</script>
<style lang="scss">
.register-activity-input input {
  height: 40px !important;
}

.custom-cols {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.register-activity-validate-form {
  width: 210px !important;
  padding: 8px 32px !important;
}
</style>
